/* For Font */

.rubik-regular {
  font-family: "Rubik-Regular";
}

.rubik-bold {
  font-family: "Rubik-Bold";
}

.rubik-light {
  font-family: "Rubik-Light";
}

.roxborough {
  font-family: "Roxborough-CF";
}

/* For Layout */
.container {
  margin: 0px auto;
  padding: 90px 120px;
  max-width: 1100px;
  min-width: 320px;
  min-height: 100vh;
  background-color: #ffffff;
}

.container-background {
  /* background-image: url("../../static/images/background-1.png");
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: #f6f6f6;
}

.pointer{
  cursor: pointer;
}

.lottie-parent {
  width: 0px;
  margin-bottom: 20px;
}

.navbar {
  text-decoration: none;
}

::selection {
  color: #E9E8E8;
  background: #181823;
}

.active{
  text-decoration: underline;
}

/* For mobile viewport */

@media only screen and (min-width: 390px) {

  /* For Layout */
  .container {
    margin: 0px auto;
    padding: 50px 30px;
    max-width: 1100px;
    min-width: 320px;
    min-height: 100vh;
    background-color: #ffffff;
  }

  .container-background {
    /* background-image: url("../../static/images/background-1.png");
  background-size: cover;
  background-repeat: no-repeat; */
    background-color: #f6f6f6;
  }
}